<template>
  <div>
    <!-- 使用qiankun微服务时不能使用动画组件 -->
    <!-- <transition name="fade-transform" mode="out-in"> -->
    <router-view :key="key" ></router-view>
    <!-- </transition> -->
    <div
      id="container"
      :style="{ display: key != '/' ? 'block' : 'none' }"
    ></div>
  </div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="less">
#container {
  // min-height: 450px;
	margin: auto;

  @media screen and (min-width: 1200px) {
    // padding: 1.0rem 0px 0.8rem 0px;
		width: 1200px;
  }
}
</style>
