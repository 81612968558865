import axios from 'axios'
import { config, cookie, common } from '@/utils'
import store from '@/store'

axios.defaults.timeout = 100000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'//'application/json; charset=utf-8'; //'application/x-www-form-urlencoded';
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

// 添加请求拦截器
axios.interceptors.request.use((req) => {
    let usertype = _global.userInfo && _global.userInfo.usertype;
    if (_global.token && req.method == 'post') req.data = { token: _global.token, usertype, ...req.data }
    if (_global.token && req.method == 'get') req.params = { token: _global.token, usertype: localStorage.getItem('usertype'), ...req.params }
    return req;
}, (error) => {
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    if (response.status == 200) {
        if (response.data.status_code == 99999 || response.data.status_code == 5001) {    // 登录过期
            // 把其他公司写的接口用到的token也清空
            let loginNum = Number.parseInt(window.localStorage.getItem("LoginNum") || '0')
            if (loginNum <= 0) {
                cookie.delCookie()
                store.commit('sqsmUser/changeLoginStatus', false);
                store.commit('sqsmUser/setUserInfo', null);
                window.localStorage.setItem("_RedirectData", "{}")
                window.localStorage.setItem("SQSM_CHILD_APP_TOKEN", "")
                window.localStorage.setItem("SQSM_VUEX", "{}")
                window.localStorage.setItem('USER__TOKEN', "")
                window.localStorage.setItem("LoginNum", loginNum + 1);
                // window.localStorage.setItem('_UrlParamsData', JSON.stringify(common.getUrlAllParams()));
                location.href = sessionStorage.getItem('SET_LOGIN_REDIRECT_URL');
                window.localStorage.setItem("LoginNum", 0);
            }

            return false
        }
    }
    // else if(response.data.status_code==1){
    //     _vue.$Message.error({content: response.data.status_msg});
    // }
    else {
        _vue.$Message.destroy()
        _vue.$Message.error({ content: code >= 500 ? '服务器发生错误，请稍后再试！' : '请求发生错误，请检查网络是否连接' });
        return false
    }
    // if(response.data.status_code==1){
    //     _vue.$Message.destroy()
    //     _vue.$Message.error({content: response.data.status_msg})
    //     return
    // }
    // 对响应数据做点什么
    return response.data;
}, (error) => {
    _vue.$Message.destroy()
    // 判断请求异常信息中是否含有超时timeout字符串
    if (error.message.includes('timeout')) {
        _vue.$Message.error({ content: '网络超时' });
        return Promise.reject(error);
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});

const install = function (Vue, config = {}) {
    Vue.prototype.$request = axios
}

export {
    install,
    axios
};
