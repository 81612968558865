import { getCartNum } from "../../api/other/cart";
import {config} from "@/utils"
export default {
    watch:{
        $route(to,from){
            if(to.path =='/wap/details'  ||to.path.indexOf('/commodity')!=-1  ||to.path.indexOf('/requirement')!=-1 || to.path.indexOf('/collection')!=-1|| to.path.indexOf('/storeSetting')!=-1   ){
                this.rightWap=false
            }else{
                this.rightWap=true
            }
        }
    },
   
    created() {

    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);

        setTimeout(() => {
            getCartNum().then(response => {
                this.cartNum = response.data
            });
        },1000)
    },
    computed: {
        key() {
            return this.$route.path;
        },
        isMobile(){
            return window.screen.width < window.screen.height
        },
        
    },

    methods: {
        doSomething(){
            this.bool=true

        },
        handleScroll() {
            this.scrollTop = document.documentElement.scrollTop;//滚动高度
        },
        goTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50
                if (top <= 0) {
                    clearInterval(timeTop)
                }
            }, 10)
        },
        // 联系客服
        contactKefu() {
            this.bool=false
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }
        },
        

        // 跳转页面
        jumpPageName(pageName, query) {
            this.$router.push({ name: pageName, query })
        }

    },

    data() {
        return {
            scrollTop: 0,
            cartNum: 0,
            bool:false,
            rightWap:false,
        }
    }
}
