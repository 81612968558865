export default{
    namespaced:true,//命名空间
    state: {
        loginStatus:false, // 是否是登录状态
        userInfo: {}, //用户信息
    },
    mutations: {
        // 改变登录状态
        changeLoginStatus(state,payload){
            state.loginStatus = payload
        },
        // 设置用户信息
        setUserInfo(state,payload){
            state.userInfo = payload
        },
    },
    actions: {
    },
}