import { render, staticRenderFns } from "./kjbtContentChild2.vue?vue&type=template&id=60c1a053&scoped=true&lang=pug"
import script from "./kjbtContentChild2.vue?vue&type=script&lang=js"
export * from "./kjbtContentChild2.vue?vue&type=script&lang=js"
import style0 from "./kjbtContentChild2.vue?vue&type=style&index=0&id=60c1a053&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c1a053",
  null
  
)

export default component.exports