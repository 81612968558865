import Cookies from 'js-cookie'


export default {
    /**
     * 设置 Cookie
     * @param {String} name     cookie 名称
     * @param {String} value    cookie 值
     * @param {Number} Days     cookie 过期时间
     */
    setCookie (name, value, Days = 7) {
        Cookies.set(name, value, {expires: Days})
    },

    /**
     * 读取 Cookie
     * @param {String} name     cookie 名称
     */
    getCookie (name = 'token') {
        return Cookies.get(name);
    },

    /**
     * 删除 Cookie
     * @param {String} name     cookie 名称
     */
    delCookie(name = 'token'){
        Cookies.remove(name);
    },

}