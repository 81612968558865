import { config } from '@/utils'
import axios from 'axios'


export default {
    name: 'customerService',
    created() {

        // if (localStorage.getItem('token')) {
            this.get_contact_unread_total()
        // };
        //20秒轮询一次未读消息数量
        this.unread_total_timer = setInterval(() => {
            // if (!localStorage.getItem('token')) return;
            this.get_contact_unread_total()
        }, 20000)
    },
    destroyed() {
        clearInterval(this.unread_total_timer)
    },

    methods: {

        get_contact_unread_total() {
            let userInfo = this.$store.state.user.userInfo
            let token = userInfo.token;
            let usertype = userInfo.usertype;
            let current_company_id = userInfo.company_id;
            axios.post(config.apiurl + 'service/contact/unread_total',{token,usertype,current_company_id},{
                withCredentials: true
              }).then(res => {
                  if (res.status_code !== 0) return this.$Message.warning(res.status_msg);
                  this.unread_total = res.obj.unread_total
            })
        },

        openkefu() {
            // this.lianxikefu = true;
            this.unread_total = 0;
            let token = localStorage.getItem('token')
            let usertype = localStorage.getItem('usertype')
            let current_company_id = localStorage.getItem('current_company_id')
            window.open(`${config.kefu}/frontend/?source=2&chat_role=1&token=${token}&usertype=${usertype}&current_company_id=${current_company_id}`)
        },


    },
    data() {
        return {

            unread_total_timer: null,
            unread_total: 0,


        }
    },
}