<template lang="pug">
.rmtj
  .list
    .item(
      v-for="(item, index) in list",
      :key="index",
      @click="activeClick(index)"
    ) 
      img.top(:src="item.imgPath")
      .name {{ item.name }}
      .desc {{ item.desc }}
      .btn {{ item.type ? item.type : title }}
</template>
<script>
import { Tab, Tabs } from "vant";
import "vant/lib/index.css";
import {config} from '@/utils'
export default {
  components: {
    Tab,
    Tabs,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      activeTab: 1,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "注册",
    },
    height: {
      type: String,
      default: "191px",
    },
    imgFile: {
      type: String,
      default: "知产服务",
    },
  },
  methods: {
    activeClick(index) {
        this.activeTab = index;
        // source(来源) 1:人是云    2:识权识美
        if(localStorage.getItem('_RedirectData') !=null){
            let ac=JSON.parse(localStorage.getItem('_RedirectData'))
            let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
            window.open(url)
        }else{
            let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
            window.open(url)
        }
    },
  },
};
</script>
<style lang="less" scoped>
.rmtj {
  background: #fff;
  box-sizing: border-box;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    .item {
      color: #666666;
      width: 33.33%;
      box-sizing: border-box;
      word-break: keep-all;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 20px;
      @media screen and (max-width: 1200px) {
        padding: 16px;
      }
      @media screen and (max-width: 1000px) {
        padding: 12px;
      }

      &:hover {
        background: #e9f9f6;
      }
      img.top {
        width: 48px;
        height: auto;
        @media screen and (max-width: 1200px) {
          width: 40px;
        }
        @media screen and (max-width: 1000px) {
          width: 32px;
        }
      }
      .name {
        font-size: 16px;
        color: #333333;
        margin: 6px 0;
        @media screen and (max-width: 1200px) {
          font-size: 15px;
          margin: 5px 0;
        }
        @media screen and (max-width: 1000px) {
          font-size: 14px;
          margin: 4px 0;
        }
      }

      .desc {
        font-size: 14px;
        color: #999999;
        white-space: pre-wrap;
        margin-bottom: 16px;
        @media screen and (max-width: 1200px) {
            font-size: 13px;
            margin-bottom: 13px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 12px;
            margin-bottom: 10px;
          }
      }
      .btn {
        border: #2ac0a3 1px solid;
        color: #2ac0a3;
        border-radius: 3px;
        padding: 7px 23px;
        font-size: 14px;
        background: #fff;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
            padding: 5px 20px;
            font-size: 13px;
          }
          @media screen and (max-width: 1000px) {
            padding: 4px 16px;
            font-size: 12px;
          }
        &:hover {
          background: #2ac0a3;
          color: #fff;
        }
      }
    }
  }

}
</style>