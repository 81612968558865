
// todo     https ? http
const config = {
    development: {
        // apiurl: '/~api',	// ~api：代理标识
        apiurl: 'https://tpa.renshihr.cn/',
        yun_domain: 'https://t.renshihr.cn',
        proxy: 'https://tpa.renshihr.cn/',
        loginUrlWap:'http://renshihr.cn',
        // kefu:'http://service.service.consul',
        kefu:'http://service.renshihr.cn',
        contribute:"http://contribute.renshihr.cn/contributeApi",//资讯
        env: 'dev'
    },
    test: {
        apiurl: 'https://tpa.renshihr.cn/',
        yun_domain: 'https://t.renshihr.cn',
        proxy: 'https://tpa.renshihr.cn/',
        loginUrlWap:'http://m.renshihr.cn',
        shiquanshimei: 'http://ippt8home.renshihr.cn',
        kefu:'http://service.renshihr.cn',
        contribute:"http://contribute.renshihr.cn/contributeApi",//资讯
        env: 'test'
    },
    production: {
        apiurl: 'https://api.renshihr.com/',
        yun_domain: 'https://www.renshihr.com',
        proxy: 'https://api.renshihr.cn/',
        loginUrlWap:'https://m.renshihr.com',
        shiquanshimei: 'https://www.ippt8.com',
        kefu:'http://service.renshihr.com',
        contribute:"http://contribute.renshihr.com/contributeApi",//资讯
        env: 'production'
    }
}
console.log(process.env.VUE_APP_CONFIG)
module.exports = config[process.env.VUE_APP_CONFIG || 'development']
