<template lang="pug">
.com-tab
    .com-tab-info( v-for="(item,i) in list"    )
        .com-tab-top(  @click="activeClick(item.id,item.name)")
            span.tit( ) {{item.name}} 
        .com-tab-bot(v-if='item.children !=null')
            .bot-info( v-for="(ite,i) in item.children") 
                .topic( )
                    .shang(v-if="item.name =='商标'" @click="activeClicke(item.id+','+ite.id,item.name+'/'+ite.name)")  {{ite.name}}  
                    p(v-else @click="activeClicke(item.id+','+ite.id,item.name+'/'+ite.name)") {{ite.name}} 
                    //- p(  @click="activeClicke(ite.id,item.name+','+ite.name)") {{ite.name}} 
                .infos
                    .fot( v-for="(ites,e) in ite.children"  )
                        p( @click="activeClickes(item.id+','+ite.id+','+ites.id,item.name+'/'+ite.name+'/'+ites.name)"  :class="activeIndex2==ites.id?'activess':''") {{ites.name}} 
    //- .com-tab-item
    //- ( v-for="(item,i) in list"  @click="activeClick(i)" :class="activeIndex==i?'active':''")
        //- span.tit {{item.name}} 
        //- span.line(v-if="list.length-1!=i")  |
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        }

    },
    watch:{
        "list"(newVal, oldVal){
            this.list=newVal
            this.activeIndex=this.list[0].id

	    }
    },
    data() {
        return {
            activeIndex: 0,
            activeIndex1: 0,
            activeIndex2: 0,
        }
    },
    created(){

    },
    methods: {
        activeClick(e,v) {

            this.$router.push({
                path:"/commodity/buy",
                query:{
                    productCategoryId:e,
                    productCategoryName:v


                }
            })
          
            // this.activeIndex1=0
            // this.activeIndex2=0
            // this.activeIndex = v;
            // this.$emit("select", this.activeIndex)
        },
        activeClicke(e,v) {
            this.$router.push({
                path:"/commodity/buy",
                query:{
                    productCategoryId:e,
                    productCategoryName:v

                }
            })
            // this.activeIndex=0
            // this.activeIndex2=0
           
            // this.activeIndex1 = v;
            // this.$emit("select", this.activeIndex1)
        },
        activeClickes(e,v) {
           this.$router.push({
                path:"/commodity/buy",
                query:{
                    productCategoryId:e,
                    productCategoryName:v

                }
            })
            // this.activeIndex=0
            // this.activeIndex1=0
            // this.activeIndex2 = v;
            // this.$emit("select", this.activeIndex2)
        }
    }
}
</script>
<style lang="less" scoped>
.com-tab {
    cursor: pointer;
    // column-count: 4;
    // column-gap: 10px;
    margin: 24px 0px;
   
    // // flex-wrap: wrap;
    // // background: #f4e1e1;
    // flex-flow:column wrap;
    // height:150vh;
    // @media screen and (max-width: 1600px) {
    //     height: 175vh;
    // }
    // @media screen and (max-width: 1200px) {
    //     height: 141vh;
    // }
    // @media screen and (max-width: 1160px) {
    //     // height: 185vh;
    //     min-width: 1060px;
    // }
    // @media screen and (max-width: 1100px) {
    //     // height: 185vh;
    //     min-width: 1000px;
    // }
    // @media screen and (max-width: 1060px) {
    //     // height: 202vh;
    //       min-width: 900px;
    // }
    //  @media screen and (max-width: 960px) {
    //     // height: 210vh;
    //      min-width: 800px;
    // }
    // height: 175vh;
    &-info {
        margin-bottom: 20px;
        // break-inside: avoid;
        // margin: 10px;
        // width: calc(100%/4.15);
    //     @media screen and (max-width: 1160px) {
    //     // height: 185vh;
    //     min-width: 1060px;
    //      width: calc(100%/4.1);
    // }
    // @media screen and (max-width: 1100px) {
    //     // height: 185vh;
    //     min-width: 1000px;
    //      width: calc(100%/4.1);
    // }
    // @media screen and (max-width: 1060px) {
    //     // height: 202vh;
    //       min-width: 900px;
    //        width: calc(100%/4.1);
    // }
    //  @media screen and (max-width: 960px) {
    //     // height: 210vh;
    //     min-width: 800px;
    //     width: calc(100%/4.1);
    // }
      
        .com-tab-top{
            height: 60px;
            text-align: left;
            line-height: 60px;
            border: 1px solid #EEEEEE;
            background: linear-gradient(154deg, #FEFCFB 0%, #F9FFFE 100%);
            padding-left: 20px;
            .tit{
                padding-left: 8px;
                position: relative;
                height: 20px;
                font-size: 18px;
                color: #333333;
                font-weight: bold;
            }
            .tit:before {
                content: "";
                background-color: #FF6B02;
                width: 3px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -8px;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
            }
            .tit:hover{
                color: #FF6B02;
                border-bottom: 1px solid #FF6B02;

            }


        }
        .com-tab-bot{
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
  

            
            // writing-mode: horizontal-tb;
            background: rgba(255,255,255,0.39);
            border-left: 1px solid #EEEEEE;
            border-right: 1px solid #EEEEEE;
            border-bottom: 1px solid #EEEEEE;
            padding:20px 20px;
            .bot-info{
                text-align: left;
                margin-right: 20px;
                .topic{
                    .shang{
                        color: #333333;
                        min-width: 95px;

                    }
                    .shang:hover{
                        text-decoration:underline;
                        color: #FF6B02;
                        font-weight: bold;

                    }
                    p{
                        color: #FF6B02;
                        

                    }
                    p:hover{
                        text-decoration:underline;
                        color: #FF6B02;
                        font-weight: bold;

                    }
                    
                    // margin-right: 5px;

                }
                
               
                .infos{
                    display: flex;
                    gap: 5px;
                    // max-width:400px;
                    flex-wrap: wrap;
                    .fot{
                        p{
                           margin-right: 5px;
                           padding: 10px 5px 0 0 ;
                           color: #333333;
                        }
                        p:hover{
                            text-decoration:underline;
                            color: #FF6B02;
                            font-weight: bold;

                        }
                    }

                }
            }
        }



    }

    @media screen and (max-width: 1200px) {
        margin: 20px 0;
    }

    @media screen and (max-width: 900px) {
        margin: 20px 0;
    }

    @media screen and (max-width: 750px) {
        margin: 0.2rem 0;
    }

    &-item {
        margin-bottom: 15px;
        span.tit {
            padding: 7px 20px;
            cursor: pointer;
            color: #333333;
            font-size: 16px;

            @media screen and (max-width: 1200px) {
                padding: 5px 15px;
                font-size: 15px;
            }

            @media screen and (max-width: 900px) {
                padding: 2px 10px;
                font-size: 32px;
            }

            @media screen and (max-width: 750px) {
                padding: 0.02rem 0.1rem;
                font-size: 0.32rem;
            }
        }

        .line {
            display: inline-block;
            color: #999999;

            @media screen and (max-width: 900px) {
                font-size: 36px;
                margin: 0 30px;
            }

            @media screen and (max-width: 750px) {
                font-size: 0.36rem;
                margin: 0 0.3rem;
            }
        }
    }
}

.active {
    .tit {
        color: #FF6B02 !important;
        text-decoration:underline;

    }

    // p {
    //     text-decoration:underline;
    //     color: #FF6B02;
    //     font-weight: bold;
    // }
    // .topic {
    //     text-decoration:underline;
    //     color: #FF6B02;
    //     font-weight: bold;
    // }
}
.actives {
    .active {
        .tit{
            // border-left: 2px solid #FF6B02;
            padding-left: 8px;
            position: relative;
            height: 20px;
            font-size: 18px;
            color: #333333 !important;
            font-weight: bold;
        }
    }
        text-decoration:underline;
        color: #FF6B02 !important;
        font-weight: bold;
    
}
.activess {
    .active{
        .tit{
            padding-left: 8px;
            position: relative;
            height: 20px;
            font-size: 18px;
            color: #333333 !important;
            font-weight: bold;
        }

    }
    
    .actives{
        .topic{
            color: #999999 !important;
        }

    }
    
   

    // p {
        text-decoration:underline;
        color: #FF6B02 !important;
        font-weight: bold;
    // }
    // .topic {
    //     text-decoration:underline;
    //     color: #FF6B02;
    //     font-weight: bold;
    // }
}
</style>