<template lang="pug">
.com-title
  .l
  span(:style="{'color':color}") {{ label }}
  .r
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#333333",
    },
  },
};
</script>
<style lang="less" scoped>
.com-title {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  margin-top: 30px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 750px) {
      margin-top: 0.3rem;
    }

  span {
    color: #333333;
    font-size: 32px;
    font-family: MicrosoftYaHei-Bold;
    margin: 0 10px;
    font-weight: 600;
    @media screen and (max-width: 1200px) {
      font-size: 30px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 28px;
    }
    @media screen and (max-width: 900px) {
      font-size: 36px;
      margin: 0 12px;
    }
    @media screen and (max-width: 750px) {
      font-size: 0.36rem;
      margin: 0 0.12rem;
    }
  }
  .l {
    display: block;
    width: 0px;
    height: 0px;
    border-top: 3px solid transparent;
    border-right: 94px solid #ff6b02;
    border-bottom: 3px solid transparent;
    border-left: 0px solid transparent;
    @media screen and (max-width: 1200px) {
        border-right: 90px solid #ff6b02;
    }
    @media screen and (max-width: 1000px) {
        border-right: 80px solid #ff6b02;
    }
    @media screen and (max-width: 900px) {
        border-right: 93px solid #ff6b02;
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
    }
    @media screen and (max-width: 750px) {
        border-right: 0.93rem solid #ff6b02;
        border-bottom: 0.03rem solid transparent;
        border-top: 0.03rem solid transparent;
    }
  }
  .r {
    display: block;
    width: 0px;
    height: 0px;
    border-top: 3px solid transparent;
    border-left: 94px solid #ff6b02;
    border-bottom: 3px solid transparent;
    border-right: 0px solid transparent;
    @media screen and (max-width: 1200px) {
        border-left: 90px solid #ff6b02;
    }
    @media screen and (max-width: 1000px) {
        border-left: 80px solid #ff6b02;
    }
    @media screen and (max-width: 900px) {
        border-left: 93px solid #ff6b02;
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
    }
    @media screen and (max-width: 750px) {
        border-left: 0.93rem solid #ff6b02;
        border-bottom: 0.03rem solid transparent;
        border-top: 0.03rem solid transparent;
    }
  }
}
</style>