import router from '../router/index.js'
const baseUrl = process.env.BASE_URL
const node_env = process.env.VUE_APP_CONFIG
console.log("baseUrl:", baseUrl)
function setApps() {
    return [
        // 商品购买模块
        {
            name: "productModule",
            // entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + "productModule/",
            entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + 'childMicros/',
            // entry:"http://172.16.1.245:9000/",
            container: "#container",
            activeRule: baseUrl + "commodity",
            props: { parentRouter: router, parentRouterBase: baseUrl + "commodity/", routePath: "productModule" ,cityId:'' }
        },
        // 需求列表模块
        {
            name: "needModule",
            // entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + "needModule/",
            entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + 'childMicros/',
            container: "#container",
            activeRule: baseUrl + "requirement",
            props: { parentRouter: router, parentRouterBase: baseUrl + "requirement/", routePath: "needModule" ,cityId:'' }
        },
        // 收藏模块
        {
            name: "collection",
            // entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + "collection/",
            entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + 'childMicros/',
            container: "#container",
            activeRule: baseUrl + "collection",
            props: { parentRouter: router, parentRouterBase: baseUrl + "collection/", routePath: "collection" ,cityId:'' }
        },
        // 店铺设置模块
        {
            name: "storeSetting",
            // entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + "storeSetting/",
            entry: node_env == 'development' ? "http://localhost:9000" + baseUrl + 'childMicros/' : node_env == 'test' ? baseUrl + 'childMicros/' : baseUrl + 'childMicros/',
            container: "#container",
            activeRule: baseUrl + "storeSetting",
            props: { parentRouter: router, parentRouterBase: baseUrl + "storeSetting/", routePath: "storeSetting",cityId:''  }
        },
    ]
}

export default {
    setApps
};
