
<template lang="pug">
  .list
    .listS
      .item(
        v-for="(item, index) in list",
        :class="[index == activeIndex ? 'actives' : '']",
        :key="index",
        @click="activeClick(index)"
      ) {{ item }}
</template>
<script>
import { Tab, Tabs } from "vant";
import "vant/lib/index.css";
export default {
  components: {
    Tab,
    Tabs,
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    hover: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    activeClick(index) {
      // this.activeTab = index;
      this.$emit("tabIndex", index);
    },
  },
};
</script>
<style lang="less" scoped>

  .list {
    width: 100%;
    margin: 0 auto;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 30px !important;
      height: 4px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    
    
    .listS {
      padding: 0.18rem 0.36rem;
      width: fit-content;
      margin: 0 auto;
      display: flex;
      background: #fff;
      .item {
 
        word-break: keep-all;
        margin: 0 0.18rem;
        font-size: 0.26rem;
        color: #666666;
  
        &:hover {
          color: #1ABC9C;
        }
        &.actives {
          color: #1ABC9C;
        }
      }    
    }
  }

</style>