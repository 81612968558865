import _ from 'lodash';
export default {
    jumpLinkUrl(baseUrl, link, isBlank=true) {
        let aElement = document.createElement("a");
        aElement.setAttribute("href", `${baseUrl}` + link);
        aElement.setAttribute("target", isBlank ? "_blank" : "_slef");
        aElement.click();
        document.body.removeChild(aElement);
    },
    /**
     * 获取url中传递的所有参数对象
     */
    getUrlAllParams () {
        // 解决乱码问题
        let url = decodeURI(window.location.href)
        let res = {}
        let url_data = _.split(url, '?').length > 1 ? _.split(url, '?')[1] : {} ;
        if (!url_data) return {}
        let params_arr = _.split(url_data, '&')
        _.forEach(params_arr, function(item) {
                let key = _.split(item, '=')[0]
                let value = _.split(item, '=')[1]
                res[key] = value
        }); 
        
        return res
    }
}