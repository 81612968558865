<template lang="pug">
.app-container
  //- (v-if="key == '/'")
  Header
  AppMain
  Footer
  ContactUs()
  CustomerService
</template>

<script>
import AppMain from "./components/AppMain.vue";
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";
import ContactUs from "@/components/contactUs/index.vue";
import CustomerService from "@/components/customerService/index.vue";
export default {
  components: { AppMain, Header, Footer, ContactUs,CustomerService },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style>
.app-container {
  width: 100%;
  min-height: 100vh;
  position: relative !important;
}
</style>