import axios from 'axios'
import { Modal, Message, Notice } from 'view-design'
import { getToken, setToken } from './auth'
import errorCode from './errorCode'
import Qs from 'qs'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000 // request timeout
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  config.headers['Content-Type'] = config.method === 'get' || config.method === 'GET' ? 'application/x-www-form-urlencoded' : 'application/json;charset=utf-8'
  if (getToken()) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    config.paramsSerializer = function (params) {
      return Qs.stringify(Object.fromEntries(Object.entries(params).filter(([k, v]) => {
        return v || v === 0
      })), {
        allowDots: true,
        arrayFormat: 'indices',
      })
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(
  response => {
    let url = process.env.VUE_APP_URL;
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || response.data.msg || errorCode['default']
    if (code === 401) {
      // setToken("")
      let loginNum = Number.parseInt(window.localStorage.getItem("LoginNum") || '0')
      if (loginNum <= 0) {
        cookie.delCookie()
        store.commit('sqsmUser/changeLoginStatus', false);
        store.commit('sqsmUser/setUserInfo', null);
        window.localStorage.setItem("_RedirectData", "{}")
        window.localStorage.setItem("SQSM_CHILD_APP_TOKEN", "")
        window.localStorage.setItem('USER__TOKEN', "")

        window.localStorage.setItem("LoginNum", loginNum + 1);
        // window.localStorage.setItem('_UrlParamsData', JSON.stringify(common.getUrlAllParams()));
        location.href = sessionStorage.getItem('SET_LOGIN_REDIRECT_URL');
        window.localStorage.setItem("LoginNum", 0);
      }
    } else if (code === 500) {
      Message.error({
        content: msg,
      })
      return Promise.reject(new Error(msg))
    } else if (code === 901) {
      Message.error({
        // type: 'error',
        // duration: 0,
        // dangerouslyUseHTMLString: true,
        content: '<div>演示模式，不能进行写操作</div>'
      })
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Notice.error({
        title: '错误',
        desc: msg,
      })
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message.error({
      content: message,
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
