"use strict";
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { config } from '@/utils'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    // base: "",
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach(async (to, from, next) => {

    // https://contract.renshihr.cn/storage/file/%E8%AE%A4%E8%AF%81%E6%8E%88%E6%9D%83%E6%A8%A1%E6%9D%BF.doc
    if(to.path.indexOf('/storage/file')>-1) return next();
    if(to.name!='Console'&&to.path!="/no_certfy"&&to.path!="/firm/approve") {
        if(_global.userInfo.usertype == 2 &&_global.userInfo.cert_status != 5) return next('/no_certfy')
        if(_global.userInfo.usertype ==1 &&_global.userInfo.cert_status!=1) return next('/no_certfy')
        if(_global.userInfo.usertype ==3){
            if(_global.userInfo.cert_status!=1 || _global.userInfo.company_status==1){
                return next('/no_certfy')
            }
        } 
    }
    return next();
    // if (_global.auths.includes(to.meta.name)) return next();
  
    // return next(_global.auth_key[_global.auths[0]])

})
//解决vue路由重复导航错误
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;



