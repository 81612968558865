import request from "../../utils/other/request.js"
export default {
    // 获得需求信息分页
    getNeedPage(data) {
        return request({
            url: '/sqsm/need/pub/page',
            method: 'get',
            params: data
        })
    },
    // 根据父类目id获取下级类目 先传入0 获取一级分类，然后传递选中id获取下级分类
    getNeedCategory() {
        return request({
            url: '/sqsm/need-category/select-parent-id?parentId=0',
            method: 'get',
            // params: data
        })
    }
}
