<template lang='pug'>
#app.layout-page
  Layout
</template>

<script type="text/javascript">
import Layout from './layout/index.vue'
import {
    config,
} from '@/utils'
export default {
  name: "App",
  components: { Layout },
  provide() {
    return {
      reload: this.reload,
    };
  },
  
  watch: {
    $route(route) {
           if(route.path=='/' ||route.path.indexOf('/commodity')!=-1  ||route.path.indexOf('/requirement')!=-1 || route.path.indexOf('/collection')!=-1|| route.path.indexOf('/storeSetting')!=-1 ){
            window.removeEventListener('resize', this.setRemPc, false);
            this.setRem()
        }
        if(route.meta.num==1){
            window.removeEventListener('resize', this.setRem, false);
            this.setRemPc()
        }
    },
    screenWidth (val) {//监听窗口大小 
        this.screenWidth = val
        if(this.screenWidth >=900){
            if(window.location.pathname.indexOf('/wap')!=-1){
                let url=window.location.pathname.split("/wap")
                this.$router.push({
                    path:url[1]+window.location.search

                })
            }else{
                this.setRemPc()
            }
        }
        if(this.screenWidth <900){
            if(this.$route.meta.num==1){
                this.$router.push({
                    path:"/wap"+window.location.pathname+window.location.search

                })
            }else{
                this.setRem()
            }
        }
        
    }
},

  computed: {
    key() {
      return this.$route.path;
        },
    },
    mounted () {
    let route=this.$route;
    console.log(route);
    if(route.path=='/' ||route.path.indexOf('/commodity')!=-1  ||route.path.indexOf('/requirement')!=-1 || route.path.indexOf('/collection')!=-1|| route.path.indexOf('/storeSetting')!=-1 ){
        window.removeEventListener('resize', this.setRemPc, false);
        this.setRem()
        // window.addEventListener('resize', setRem)   
    }
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },

  methods: {
    setRem() {
     
        let num=document.body.clientWidth
        if (num <= 900&&num>750) {
        var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
        var rem = 750 * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
        }
        if (num <= 750) {
        var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
        var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
        var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
        
        }

    },
    setRemPc() {
        window.removeEventListener('resize', this.setRem, false);

        document.getElementsByTagName('html')[0].style.fontSize = 'unset';
        var whdef = 100 / 1920; // 表示750的设计图,使用100PX的默认值
        var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
        var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        
        // window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
        //     whdef = window.innerWidth;
        //     rem = bodyWidth / whdef;
        //     window.removeEventListener('resize', this.setRem, false);
        // }
        setTimeout(()=>{
            this.$nextTick(()=>{
                window.removeEventListener('resize', this.setRem, false);
                document.getElementsByTagName('html')[0].style.fontSize = 'unset';
                // console.log('pc',document.getElementsByTagName('html')[0].style.fontSize);

            })
        },0)
        
        
    },
  
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },

  data() {
    return {
        config,
        isRouterAlive: true,
        //实时屏幕宽度
        screenWidth: document.body.clientWidth
    };
  },
};
</script>
<style lang="less" scoped>
.layout-page {
  position: relative;
  padding: 0px;

  @media screen and (max-width: 900px) {
    width: 750px;
    margin: 0 auto;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
<style>
.line-1 {
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #FF6B02;
}
</style>
