//src目录下新建一个store目录，创建index.js如下
//创建数据仓库
import Vuex from 'vuex';
import Vue from 'vue';
import user from './user.js'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({ key: "SQSM_VUEX" })],
    modules: {  
        user //是否正在登录中和登录的用户信息
    },
});
