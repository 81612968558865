import { request, config } from '@/utils'

const api = {

    userInfo() {  // 获取登录用户信息
        return request.post(`${config.apiurl}api/user/info`)
    },
    loginout() {  // 获取登录用户信息
        return request.post(`${config.apiurl}api/user/logout`)
    },
    getUnreadMessageCount(){
        return request.post(`${config.apiurl}api/v1/message/my/unread_count`)
    },
    //客服未读消息数量
    getServiceUnreadMessageCount(params){
        return request.post(`${config.apiurl}service/contact/unread_total`,params)
    },
}

export default api;
