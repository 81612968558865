<template lang="pug">
.listBig
  .list
    .item(
      v-for="(item, index) in list",
      :key="index",
      @click="activeClick(index, item.link)"
    ) 
      img.fm(:src="item.pic")
      .price ￥{{ item.price }}
      .name.line-1 {{ item.name }}
      .author
        img.at( :src="item.productStore?item.productStore.logo: ''")
        .name {{ item.merchantName.length < 8 ? item.merchantName : item.merchantName.slice(0, 8) + '...' }}
        //.type 转让
      .go
        img(src="./images/huaguo.png")
</template>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    activeClick(index, link) {
      this.activeTab = index;
      // window.open(link);
      this.$router.push({ name: "ProductDetail", query: { view: false, id: this.list[index].id } })
    },
  },
};
</script>
<style lang="less" scoped>
.listBig {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .list {
    margin: 0 auto;
    display: flex;
    // justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
      padding: 0 20px;
      justify-content: space-between;
    }

    @media screen and (max-width: 750px) {
      padding: 0 0.2rem;
      justify-content: space-between;
    }

    .item {
      width: 200px;
      height: 325px;
      border: 1px solid #dddddd;
      margin: 10px;
      text-align: left;
      position: relative;

      @media screen and (max-width: 1200px) {
        width: 180px;
        height: 290px;
        margin: 8px;
      }

      @media screen and (max-width: 900px) {
        width: 345px;
        height: 437px;
        margin: 0;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 750px) {
        width: 3.45rem;
        height: 4.37rem;
        margin: 0;
        margin-bottom: 0.2rem;
      }

      &:hover {
        .go {
          display: block;
          cursor: pointer;
        }

        .type {
          color: #3cc8ac;
          background-color: #3cc8ac;
        }
      }

      .go {
        position: absolute;
        top: 0;
        z-index: 99;
        width: 200px;
        height: 325px;
        background: #0000007e;
        display: none;
        transition: all 0.2s ease;

        @media screen and (max-width: 1200px) {
          width: 180px;
          height: 290px;
        }

        @media screen and (max-width: 900px) {
          width: 345px;
          height: 437px;

        }

        @media screen and (max-width: 750px) {
          width: 3.45rem;
          height: 4.37rem;
        }

        img {
          width: 52px;
          height: 52px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -26px 0 0 -26px;

          @media screen and (max-width: 1200px) {
            width: 48px;
            height: 48px;
            margin: -24px 0 0 -24px;
          }

          @media screen and (max-width: 900px) {
            width: 80px;
            height: 80px;

          }

          @media screen and (max-width: 750px) {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }

      .fm {
        width: 200px;
        height: auto;
        background: #2e403a;

        @media screen and (min-width: 1201px) {
          width: 200px;
          height: 200px;
        }

        @media screen and (max-width: 1200px) {
          width: 180px;
          height: 180px;
        }

        @media screen and (max-width: 900px) {
          width: 345px;
          height: 254px;

        }

        @media screen and (max-width: 750px) {
          width: 3.45rem;
          height: 2.54rem;
        }

      }

      .price {
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #ff3300;
        padding-left: 8px;
        margin: 6px 0;

        @media screen and (max-width: 1200px) {
          margin: 5px 0;
          font-size: 18px;
          padding-left: 6px;
        }

        @media screen and (max-width: 900px) {
          font-size: 36px;
          padding-left: 20px;
          margin: 5px 0 0px;

        }

        @media screen and (max-width: 750px) {
          font-size: 0.36rem;
          padding-left: 0.2rem;
          margin: 0.05rem 0 0rem;
        }
      }

      .name {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #333333;
        padding-left: 8px;

        @media screen and (max-width: 1200px) {
          font-size: 16px;
          padding-left: 6px;
        }

        @media screen and (max-width: 900px) {
          font-size: 28px;
          padding-left: 20px;
        }

        @media screen and (max-width: 750px) {
          font-size: 0.28rem;
          padding-left: 0.2rem;
        }
      }

      .author {
        display: flex;
        //   justify-content: space-between;
        margin: 10px;
        border-top: 1px solid #dddddd;
        position: relative;

        padding-top: 10px;

        @media screen and (max-width: 1200px) {
          margin: 8px;
          padding-top: 8px;
        }

        @media screen and (max-width: 900px) {
          margin: 16px 20px 0;
          padding-top: 12px;

        }

        @media screen and (max-width: 750px) {
          margin: 0.16rem 0.2rem 0;
          padding-top: 0.12rem;
        }

        .at {
          width: 24px;
          height: 24px;
          background: #dbfbe4;
          border-radius: 50%;

          @media screen and (max-width: 1200px) {
            width: 20px;
            height: 20px;
          }

          @media screen and (max-width: 900px) {
            width: 34px;
            height: 34px;

          }

          @media screen and (max-width: 750px) {
            width: 0.34rem;
            height: 0.34rem;
          }

        }

        .name {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #666666;
          margin-left: 5px;

          @media screen and (max-width: 1200px) {
            font-size: 15px;
            margin-left: 4px;
          }

          @media screen and (max-width: 900px) {
            font-size: 24px;
            margin-left: 12px;

          }

          @media screen and (max-width: 750px) {
            font-size: 0.24rem;
            margin-left: 0.12rem;
          }
        }

        .type {
          padding: 3px 8px;
          position: absolute;
          right: 0;

          background: #ffebdd;
          color: #ff6b02;
          text-align: center;
          border-radius: 2px;

          @media screen and (max-width: 1200px) {
            padding: 2px 6px;
          }

          @media screen and (max-width: 900px) {
            font-size: 18px;
            padding: 2px 8px;

          }

          @media screen and (max-width: 750px) {
            font-size: 0.18rem;
            padding: 0.02rem 0.08rem;
          }

        }
      }
    }
  }

  .active {
    color: #1abc9c;
    border-bottom: 3px solid #1abc9c;
  }
}
</style>