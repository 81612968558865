<template lang="pug">
.rmtj
  .list
    .item(
      v-for="(item, index) in list",
      :key="index",
      @click="activeClick(index)"
    ) 
      .top
        img(:src="item.imgPath")
        .name {{ item.name }}
      .desc {{ item.desc }}
      .btn {{ item.type ? item.type : title }}
</template>
<script>
import { Tab, Tabs } from "vant";
import "vant/lib/index.css";
import {config} from '@/utils'
export default {
  components: {
    Tab,
    Tabs,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      activeTab: 1,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "注册",
    },
    height: {
      type: String,
      default: "191px",
    },
    imgFile: {
      type: String,
      default: "知产服务",
    },
  },
  methods: {
    activeClick(index) {
      this.activeTab = index;
        // source(来源) 1:人是云    2:识权识美
        if(localStorage.getItem('_RedirectData') !=null){
            let ac=JSON.parse(localStorage.getItem('_RedirectData'))
            let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
            window.open(url)
        }else{
            let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
            window.open(url)
        }
    },
  },
};
</script>
<style lang="less" scoped>
.rmtj {
  
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    .item {
      text-align: left;
      color: #666666;
      width: 48.5%;
      box-sizing: border-box;
      margin-bottom: 0.2rem;
      background: #fff;
      padding: 0.3rem;
      position: relative;
     
      .top{
        display: flex;
        align-items: center;
        img {
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.1rem;
        }
        .name {
          font-size: 0.28rem;
          color: #666666;
        }
      }

      .desc {
        font-size: 0.24rem;
        color: #666666;
        white-space: pre-wrap;
        margin: 0.1rem 0 0;
        padding-bottom: 0.8rem;
        
      }
      .btn {
        position: absolute;
        bottom: 0.3rem;
        left: 0.3rem;
        display: inline-block;
        border: #1ABC9C 1px solid;
        color: #1ABC9C;
        border-radius: 0.5rem;
        padding: 0.06rem 0.54rem;
        font-size: 0.24rem;
        background: #fff;
        cursor: pointer;
       
        &:hover {
          background: #1ABC9C;
          color: #fff;
        }
      }
    }
  }

}
</style>