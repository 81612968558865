import request from "../../utils/other/request.js"

export default {
    // 登录
    login(data) {
        return request({
            url: '/sqsm/user/login',
            method: 'post',
            data: data
        })
    },
    // 登出
    userLogout(data) {
        return request({
            url: '/sqsm/user/loginOut',
            method: 'get',
            params: data
        })
    }
}

