import request from "../../utils/other/request.js"

export default {
    // 获得公开的商品信息
    getGoodsPage(data) {
        return request({
            url: '/sqsm/product/pub/page',
            method: 'get',
            params: data
        })
    },
   
    // 获得公开的商品分类信息
    getListProductCategory(data) {
        return request({
            url: '/sqsm/product/pub/listProductCategory',
            method: 'get',
            params: data
        })
    },
    // 获取交易快报
    getNewsflash(data) {
        return request({
            url: '/sqsm/newsflash/getNewsflash',
            method: 'get',
            params: data
        })
    },
    // 筛选商品
    getProductStoresearch(data) {
        return request({
            url: '/sqsm/product-search/search',
            method: 'post',
            data: data
        })
    },
}


