import request from "../../utils/other/request.js"
export default {
    // 获得识全识美商家信息分页
    getProductStorePage(data) {
        return request({
            url: '/sqsm/product-store/pub/page',
            method: 'get',
            params: data
        })
    },
    
    

}