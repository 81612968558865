import { render, staticRenderFns } from "./App.vue?vue&type=template&id=ed7be5a8&scoped=true&lang=pug"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=ed7be5a8&prod&lang=less&scoped=true"
import style1 from "./App.vue?vue&type=style&index=1&id=ed7be5a8&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed7be5a8",
  null
  
)

export default component.exports